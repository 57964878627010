#compareTitle{
    scroll-margin-top:150px ;
    scroll-snap-margin-top:150px ;
    
}

#compareTitlePop{
    scroll-margin-top:160px ;
    scroll-snap-margin-top:160px ;
    
}

@media only screen and (min-height: 844px) and (orientation: portrait){
    #compareTitle{
        scroll-margin-top:200px ;
        scroll-snap-margin-top:200px ;
        
    }

    #compareTitlePop{
        scroll-margin-top:250px ;
        scroll-snap-margin-top:250px ;
        
    }
}

@media only screen and (min-width: 390px) and (orientation: landscape){
    #compareTitle{
        scroll-margin-top:70px ;
        scroll-snap-margin-top:70px;
    }

    #compareTitlePop{
        scroll-margin-top:130px ;
        scroll-snap-margin-top:130px ;
        
    }
}

@media only screen and (width>= 1200px) and (orientation: landscape){
    #compareTitle{
        scroll-margin-top:100px ;
        scroll-snap-margin-top:100px;
    }
}

@media (width>= 992px) and (width< 1200px) and (orientation: landscape){
    #compareTitle{
        scroll-margin-top:200px ;
        scroll-snap-margin-top:200px;
    }

    #compareTitlePop{
        scroll-margin-top:240px ;
        scroll-snap-margin-top:240px ;
    }
}

@media (orientation: portrait) and (height<= 740px) {
    #compareTitle{
        scroll-margin-top:190px ;
        scroll-snap-margin-top:190px;
    }

    #compareTitlePop{
        scroll-margin-top:260px ;
        scroll-snap-margin-top:260px ;
        
    }
}

@media (orientation: portrait) and (width < 1024) {
    #compareTitle{
        scroll-margin-top:60px ;
        scroll-snap-margin-top:60px;
    }
}

@media (orientation: landscape)and (height < 200) {
    #compareTitle{
        scroll-margin-top:70px ;
        scroll-snap-margin-top:70px
    }
}

  @media (width >=1200) {
    #compareTitle{
        scroll-margin-top:130px ;
        scroll-snap-margin-top:130px
    }
  }

@media (orientation: landscape) and (width>=1024) {
    #compareTitle{
        scroll-margin-top:200px ;
        scroll-snap-margin-top:200px
    }
}

@media  (width>=1024) {
    #compareTitle{
        scroll-margin-top:130px ;
        scroll-snap-margin-top:130px;
    }
}